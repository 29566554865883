.pmf-voting-star {
  background: none;
  border: none;
  font-size: 1.5em;
  padding: 0 0.15em;

  &.selected {
    color: $primary;
  }
}

.pmf-stars-screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.share-modal-body {
  .icons {
    margin: 15px 0px 20px 0px;

    a {
      text-decoration: none;
      border: 1px solid transparent;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: transparent;

        span {
          transform: scaleX(1.1);
        }
      }

      &:nth-child(1) {
        color: #007ab6;
        border-color: #b7d4fb;

        &:hover {
          background-color: #007ab6;
          color: #fff;
        }
      }

      &:nth-child(2) {
        color: #ff4500;
        border-color: #ffdac4;

        &:hover {
          background-color: #ff4500;
          color: #ffdac4;
        }
      }

      &:nth-child(3) {
        color: #25d366;
        border-color: #bef4d2;

        &:hover {
          background-color: #25d366;
          color: #fff;
        }
      }

      &:nth-child(4) {
        color: #0088cc;
        border-color: #b3e6ff;

        &:hover {
          background-color: #0088cc;
          color: #fff;
        }
      }

      span {
        transition: all 0.09s ease-in-out;
      }
    }
  }

  .field {
    margin: 15px 0px -5px 0px;
    height: 45px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 5px;

    span {
      width: 50px;
      font-size: 1.1rem;
    }

    input {
      border: none;
      outline: none;
      font-size: 0.89rem;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 330px) {
  .modal-body {
    .icons {
      a {
        margin-right: 15px;
        width: 35px;
        height: 35px;
      }
    }
  }
}
